<template>
  <el-container style="height: 95vh">
    <el-header class="header">
      <div class="header-content">
        <div class="logo" @click="goHome">
          <img src="https://your-icon-url.com/icon.png" alt="Logo" />
        </div>
        <div class="nav-links">
          
          
          <el-button @click="goUserCreate"   class="home-link">添加用户</el-button>
        
          <el-button @click="goAdminOrder" link  class="home-link-no">扫描订单</el-button>
        </div>
      </div>
    </el-header>
    <el-main class="main">
      <div class="warp">
        <el-form class="form-info" :model="formData">
          <el-form-item >
            
            <el-input
              v-model="formData.userCode"
              style="max-width: 600px"
              placeholder="客户代码"
              class="number-input"
            >
            </el-input>
            
          </el-form-item>

          <el-form-item >
            
            <el-input
              v-model="formData.phone"
              style="max-width: 600px"
              placeholder="手机号"
              class="number-input"
            >
            </el-input>
            
          </el-form-item>


          <el-form-item >
            
            <el-input
              v-model="formData.wechat"
              style="max-width: 600px"
              placeholder="微信名"
              class="number-input"
            >
            </el-input>
            
          </el-form-item>

          
          <el-form-item >
            
            <el-input
              v-model="formData.userName"
              style="max-width: 600px"
              placeholder="名字"
              class="number-input"
            >
            </el-input>
            
          </el-form-item>

          
          <el-form-item >
            
            <el-input
              v-model="formData.email"
              style="max-width: 600px"
              placeholder="邮箱"
              class="number-input"
            >
            </el-input>
            
          </el-form-item>

          
          <el-form-item >
            
            <el-input
              v-model="formData.remark"
              style="max-width: 600px"
              placeholder="备注"
              class="number-input"
            >
            </el-input>
            
          </el-form-item>
          <el-form-item>
            <el-button class="button-info" @click="onSubmit"
              >添加</el-button
            >
        </el-form-item>
        </el-form>
        <div class="table-header-count">
          <el-text class="mx-1" type="success"
            >用户明细（{{ tableCount }}）</el-text
          >
        </div>
        <div>
          <el-card>
            <el-table :data="tableData" border style="width: 100%,  text-align: center">
              <el-table-column prop="userCode" label="客户代码" width="120" />
              <el-table-column
                prop="relationType"
                label="服务类型"
                width="250"
              >
              <template #default="scope">
                  <span>{{ getServiceText(scope.row.relationType) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="isPhoto" label="产品拍照" width="120" >
                <template #default="scope">
                  <span>{{ getPhotoText(scope.row.isPhoto) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="isOpenCheck" label="开箱质检" width="120" >
                <template #default="scope">
                  <span>{{ getOpenCheckText(scope.row.isOpenCheck) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="上传时间" width="250"  >
                <template #default="scope">
                  <span>{{ formatDateTime(scope.row.createTime) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态" width="120" >
                <template #default="scope">
                  <span>{{ getStatusText(scope.row.status) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="overTime" label="贴单时间" width="250" >
                <template #default="scope">
                  <span>{{ formatDateTime(scope.row.overTime) }}</span>
                </template>
              </el-table-column>
              <el-table-column  
                label="国内单号"  
                width="120">  
                <template #default="scope">  
                  <!-- 使用 v-html 指令来渲染包含换行符的字符串，但请注意 v-html 可能带来的 XSS 风险 -->  
                  <span v-html="scope.row.trackingNumber.replace(/\n/g, '<br>')"></span>  
                </template>  
              </el-table-column>  
              <el-table-column
                prop="ozonOrderNumber"
                label="OZON单号"
                width="120"
              >
              
              <template #default="scope">  
                  <!-- 使用 v-html 指令来渲染包含换行符的字符串，但请注意 v-html 可能带来的 XSS 风险 -->  
                  <span v-html="scope.row.ozonOrderNumber.replace(/\n/g, '<br>')"></span>  
                </template>  
              </el-table-column>  
              <el-table-column prop="remark" label="备注" width="150" />
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button
                    size="small"
                    :disabled="scope.row.status === 1"  
                    @click="handleOver(scope.row.trackingNumber)"
                  >
                    完成
                  </el-button>
                  <el-button
                    size="small"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                  >
                    异常
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </div>
      </div>
    </el-main>
  </el-container>
</template>  

<script>
import axios from "axios";
export default {
  data() {
    return {
      formData: {
        userCode: "",
        warehouse: "",
        type: "1",
        trackingNumber: [],
        serviceTypeList: [],
        statusList: [],
        errorList: [],
        number: "",
        remark: "",
        numberType: "1",
        checkList: [],
        ozonOrderNumber: [],
        
      },
      overData:{
        trackingNumberIds:"",
      },
      tableData: [],
      tempDomesticNumber: "",
      tableCount: 0,
      warehouses: [
        { value: "warehouse1", label: "仓库1" },
        { value: "warehouse2", label: "仓库2" },
        // 更多仓库...
      ],
      
      apiUrl: "https://uni.elsbhl.com/wl/api/getPdf?id=",
      accessToken: localStorage.getItem('logintoken'),
    };
  },
  methods: {
    goAdminOrder() {
      this.$router.push({ name: "AdminOrderListPage" });
    },
    goUserCreate() {
      this.$router.push({ name: "UserCreate" });
    },
    formatDateTime(cellValue) {  
      console.log("cellValue");
      console.log(cellValue);
      if(cellValue == null || cellValue == undefined || cellValue == ""){
        return "";
      }
      // 假设cellValue是createTime的值  
      // 转换日期时间字符串为Date对象  
      const date = new Date(cellValue);  
  
      // 使用Date对象的方法获取年、月、日、时、分、秒  
      const year = date.getFullYear();  
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份是从0开始的  
      const day = ('0' + date.getDate()).slice(-2);  
      const hours = ('0' + date.getHours()).slice(-2);  
      const minutes = ('0' + date.getMinutes()).slice(-2);  
      const seconds = ('0' + date.getSeconds()).slice(-2);  
  
      // 拼接成所需的格式  
      return `${year}年${month}月${day}日${hours}:${minutes}:${seconds}`;  
    },  
    getStatusText(status) {
      switch (status) {
        case 0:
          return '未操作';
        case 1:
          return '完成';
        case 2:
          return '异常';
        default:
          return '未知状态';
      }
    },

    getServiceText(relationType) {
      switch (relationType) {
        case 1:
          return '一个国内订单一个OZON订单';
        case 2:
          return '一个国内订单多个OZON订单';
        case 3:
          return '多个国内订单多个OZON订单';
        default:
          return '未知状态';
      }
    },


    getPhotoText(isPhoto) {
      if(isPhoto){
        return '是';
      }else{
return '否';
      }
    },

    getOpenCheckText(isOpenCheck) {
      if(isOpenCheck){
        return '是';
      }else{
return '否';
      }
    },

    handleStatusAll(event) {
      console.log(event);
      // 当全选框状态改变时触发
      if (event) {
        // 如果全选被勾选，则添加所有选项到formData.checkList
        this.formData.statusList = ["-1", "0", "1", "2"];
      } else {
        // 如果全选未被勾选，则清空formData.checkList，但保留"-1"以表示全选的状态（如果需要的话）
        // 注意：如果你不想在全选未勾选时保留"-1"，则直接清空数组
        this.formData.statusList = []; // 或者 this.formData.checkList = ["-1"] 如果你想保留"-1"
      }
    },
    handleServiceAll(event) {
      console.log(event);
      // 当全选框状态改变时触发
      if (event) {
        // 如果全选被勾选，则添加所有选项到formData.checkList
        this.formData.serviceTypeList = ["0", "1", "2", "3"];
      } else {
        // 如果全选未被勾选，则清空formData.checkList，但保留"-1"以表示全选的状态（如果需要的话）
        // 注意：如果你不想在全选未勾选时保留"-1"，则直接清空数组
        this.formData.serviceTypeList = []; // 或者 this.formData.checkList = ["-1"] 如果你想保留"-1"
      }
    },

    handleErrorAll(event) {
      console.log(event);
      // 当全选框状态改变时触发
      if (event) {
        // 如果全选被勾选，则添加所有选项到formData.checkList
        this.formData.errorList = ["-1", "1", "2", "3", "4", "5", "6", "7"];
      } else {
        // 如果全选未被勾选，则清空formData.checkList，但保留"-1"以表示全选的状态（如果需要的话）
        // 注意：如果你不想在全选未勾选时保留"-1"，则直接清空数组
        this.formData.errorList = []; // 或者 this.formData.checkList = ["-1"] 如果你想保留"-1"
      }
    },

    handleClose(index) {
      this.formData.trackingNumber.splice(index, 1); // 从数组中移除对应项
    },
    validateCustomerCode(value) {
      // 客户代码的验证逻辑
      // if (!/^[A-Z]+$/.test(value)) {
      //   this.$message.error('客户代码必须为大写字母，且不能有空格！');
      //   this.formData.customerCode = ''; // 可选：清空错误输入
      // }
      console.log(value);
    },
    checkCustomerCode() {
      const code = this.formData.userCode;
      if (code) {
        axios
          .get(
            `https://uni.elsbhl.com/postOrder/logistics/user/${encodeURIComponent(code)}`
          )
          .then((response) => {
            // 假设后端返回200表示验证成功，你可以根据后端实际返回的数据来调整这里的逻辑
            if (response.status === 200) {
              console.log(response);
              if (response.data.data === true) {
                
                this.$message.info("客户代码验证成功！");
              } else {
                this.$message.error("客户代码验证失败！");
              }
              // 验证成功，你可以在这里添加成功后的逻辑
            } else {
              // 验证失败
              this.$message.error("查询失败");
            }
          })
          .catch((error) => {
            // 请求出错
            this.$message.error("请求出错，请稍后再试！");
            console.error(error);
          });
      } else {
        this.$message.warning("请先填写客户代码！");
      }
    },
    handleServiceTypeChange() {
      console.log(this.formData.type);
      // 服务类型变化时可能需要的逻辑处理
    },
    onSubmit() {
      axios
        .post("https://uni.elsbhl.com/postOrder/logistics/user", this.formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.status === 200){
            this.$message.success(response.data.msg);
          }else{
            this.$message.error(response.data.msg);
          }
          // 请求成功处理
          console.log("订单提交成功", response);
          // 这里可以添加其他成功处理逻辑
        })
        .catch((error) => {
          // 请求失败处理
          this.$message.error(error.response.data.msg);
          console.error(error);
          // 这里可以添加其他失败处理逻辑
        
          
            console.error(error);
          // 这里可以添加其他失败处理逻辑
        });
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>  

<style scoped>
.custom-radio-group {
  width: 100%;
  border: 1px solid #dcdfe6; /* 添加边框 */
  padding: 15px; /* 添加内边距 */
}

.custom-label {
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
  border-left: 5px solid #fc671a;
  font-size: 16px;
  margin: 10px 0px;
  padding: 0 10px;
  align-content: center;
  text-align: left;
}

.custom-label span {
  font-size: 14px;
  color: #999;
}

.label-border {
  margin-right: 15px; /* 调整单选按钮之间的间距 */
  width: 100%;
  border: 0px solid #dcdfe6; /* 添加边框 */
  padding: 15px; /* 添加内边距 */
  background-color: #333;
}

.header {
  height: 120px; /* 设置header的高度 */
  background-image: url("https://yunfulfillment.yunexpress.cn/assets/ytnew/images/cxbn_01.jpg");
  background-size: cover; /* 使背景图像覆盖整个元素 */
  background-position: center; /* 居中背景图 */
  color: #333;
  width: 100%; /* 确保header填满左右 */
  padding: 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* 添加内边距以避免内容紧贴边缘 */
  height: 100%; /* 确保内容高度与header一致 */
}

.logo img {
  height: 100%; /* 调整图标高度以填满header */
  cursor: pointer; /* 鼠标悬停时显示为手形光标 */
}

.nav-links {
  display: flex; /* 使用flex布局排列链接 */
  gap: 10px; /* 调整链接之间的间距，根据需要可增加或减少 */
}

.home-link {
  background-color: #ffffff; /* 背景为纯白 */
  height: 100%; /* 与header高度相同 */
  line-height: 120px; /* 与header高度一致，垂直居中 */
  padding: 0 20px; /* 添加左右内边距，适当控制左右间距 */
  text-decoration: none; /* 移除下划线 */
  color: #fc671a; /* 字体颜色 */
  display: flex; /* 使用flex更好地居中内容 */
  align-items: center; /* 垂直居中 */
  
  font-size: 15px;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 0px;
}

.home-link-no {
  height: 100%; /* 与header高度相同 */
  line-height: 120px; /* 与header高度一致，垂直居中 */
  padding: 0 20px; /* 添加左右内边距，适当控制左右间距 */
  text-decoration: none; /* 移除下划线 */
  color: #ffffff; /* 字体颜色 */
  display: flex; /* 使用flex更好地居中内容 */
  align-items: center; /* 垂直居中 */
  font-size: 15px;
  
  margin-right: 10px;
}
.footer {
  height: 160px; /* 设置footer的高度 */
  background-color: #212429;
  color: #333;
  text-align: center;
  line-height: 60px; /* 与footer高度一致，垂直居中 */
  width: 100%; /* 确保footer填满左右 */
}

.main {
  background-color: #ffffff;
  justify-content: center;
  color: #333;
  display: flex;;
  padding-top: 0; /* 如果有的话，移除或调整这个值 */
  margin-top: 0; /* 如果有的话，移除或调整这个值 */
}

.i-info {
  margin: 2px;
  color: red;
}
.span-info {
  width: 40%;
}
.form-info {
  margin-top: 0; /* 移除或调整顶部外边距 */
  border: 1px solid #dcdfe6; /* 添加边框 */
  width: 100%;
  border-radius: 0px; /* 可选：添加圆角边框 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); /* 可选：添加阴影 */
  height: 80%;  
}

.custom-label .el-form-item__label {
  background-color: #f5f5f5; /* 替换成你想要的颜色 */
  padding: 5px; /* 可选：调整内边距以增加背景的可视效果 */
}

.prepend-text {
  height: 50%;
  padding-left: 50px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); /* 透明度较高的阴影 */
}

.custom-label .el-form-item__label {
  background-color: #f5f5f5; /* 替换成你想要的颜色 */
  padding: 5px; /* 可选：调整内边距以增加背景的可视效果 */
}

.prepend-text {
  height: 50px;
  font-weight: bold; /* 字体加粗 */
  align-content: center;
}
.user-code-input {
  width: 20%;
}
.number-input {
  width: 20%;
  margin-top: 10px;
  margin-left:  660px;
  height: 50px;
}

.button-info {
  margin-top: 30px;
  margin-left: 780px;
  height: 40px;
  width: 150px;
  border-radius: 0px;
  background-color: #fc671a;
  font-size: 20px;
  color: #fff;
}
.import-button {
  border-radius: 10px;
  background: #999999;
  width: 150px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  padding: 10px 18px;
  text-align: center;
  margin-right: 24px;
}
.mx-1 {
  margin-left: 10px;
}
.date-range-picker {
  display: flex;
  align-items: center;
}

.date-range-picker input {
  flex: 1;
  margin: 0 5px; /* 两边各留出5px的间隔 */
}

.separator {
  padding: 0 10px; /* 设置文字间的间隔 */
  font-size: 14px; /* 设置文字大小 */
}
.table-header-count {
  height: 50px;
  border: 0px solid #f5f5f5;
  background-color: #f5f5f5;
  width: 100%;
  margin-top: 20px;
  align-content: center;
}
.el-card {
  margin-top: 20px;
  width: 100%;
}
.label-font-size {
  font-size: 22px;
}
.warp {
  width: 1920px;
}

.el-table {
  --el-table-header-bg-color: #fc671a;
  --el-table-header-text-color: #fff;
}


</style>