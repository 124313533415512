<template>  
  <div id="app">  
    <router-view />  
  </div>  
</template>  
  
<script>  
export default {  
  name: 'App'  
}  
</script>  
  
<style>  
/* 全局样式可以在这里添加 */  
</style>  