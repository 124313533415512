<template>
  <el-container style="height: 95vh">
    <el-header class="header">
      <div class="header-content">
        <div class="logo" @click="goHome">
          <img src="https://your-icon-url.com/icon.png" alt="Logo" />
        </div>
        <div class="nav-links">
          <el-button @click="goHome" link class="home-link-no">首页</el-button>

          <el-button @click="goOrderPage" link class="home-link-no"
            >提交订单</el-button
          >

          <el-button @click="goListPage" class="home-link">查询订单</el-button>
        </div>
      </div>
    </el-header>
    <el-main class="main">
      <div class="warp">
        <el-form class="form-info" :model="formData">
          <el-form-item label-position="top">
            <el-input
              v-model="formData.userCode"
              placeholder="正确填写客户代码"
              class="user-code-input"
              @blur="checkCustomerCode"
            >
              <template #prepend>
                <span>客户代码</span>
              </template>
            </el-input>
            <el-input
              v-model="formData.number"
              style="max-width: 600px"
              placeholder="请输入单号"
              class="number-input"
            >
              <template #prepend>
                <el-select
                  v-model="formData.numberType"
                  placeholder="Select"
                  class="number-type-select"
                >
                  <el-option label="国内单号" value="1" />
                  <el-option label="OZON单号" value="2" />
                </el-select>
              </template>
            </el-input>
            <div class="demo-date-picker">
              <div class="block">
                <el-date-picker
                  v-model="formData.createStartDate"
                  type="date"
                  placeholder="选择登记开始日期"
                ></el-date-picker>
                ~
                <el-date-picker
                  class="date-picker-container"
                  v-model="formData.createEndDate"
                  type="date"
                  placeholder="选择登记结束日期"
                  :picker-options="pickerOptions"
                  prefix-icon="Calendar"
                ></el-date-picker>
              </div>
            </div>
            <div class="demo-date-picker">
              <div class="block">
                <el-date-picker
                  v-model="formData.overStartDate"
                  type="date"
                  placeholder="选择完成开始日期"
                ></el-date-picker>
                ~
                <el-date-picker
                  class="date-picker-container"
                  v-model="formData.overEndDate"
                  type="date"
                  placeholder="选择完成结束日期"
                  :picker-options="pickerOptions"
                  prefix-icon="Calendar"
                ></el-date-picker>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="服务类型" class="label-font-size">
            <el-checkbox-group v-model="formData.serviceTypeList">
              <el-checkbox @change="handleServiceAll" label="全选" value="0" />
              <el-checkbox
                label="一个国内订单一个OZON订单"
                value="1"
              ></el-checkbox>
              <el-checkbox
                label="一个国内订单多个OZON订单"
                value="2"
              ></el-checkbox>
              <el-checkbox
                label="多个国内订单多个OZON订单"
                value="3"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="状态类型">
            <el-checkbox-group v-model="formData.statusList">
              <el-checkbox @change="handleStatusAll" label="全选" value="-1" />
              <el-checkbox label="未操作" value="0" />
              <el-checkbox label="完成" value="1" />
              <el-checkbox label="异常" value="2" />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="异常类型">
            <el-checkbox-group v-model="formData.errorList">
              <el-checkbox @change="handleErrorAll" label="全选" value="-1" />
              <el-checkbox label="拍照异常" value="1" />
              <el-checkbox label="开箱质检异常" value="2" />
              <el-checkbox label="国内单号异常" value="3" />
              <el-checkbox label="产品或产品数量异常" value="4" />
              <el-checkbox label="暂存" value="5" />
              <el-checkbox label="OZON订单异常" value="6" />
              <el-checkbox label="尺寸重量不合规" value="7" />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <div class="button-container">
              <el-button class="button-info" @click="onSubmit">搜索</el-button>
            </div>
          </el-form-item>
        </el-form>
        <div class="table-header-count">
          <el-text class="mx-1" type="success"
            >订单明细（{{ tableCount }}）</el-text
          >
        </div>
        <div>
          <el-card>
            <el-table
              :data="tableData"
              border
              style="width: 100%,  text-align: center"
            >
              <el-table-column prop="userCode" label="客户代码" width="120" />
              <el-table-column prop="relationType" label="服务类型" width="250">
                <template #default="scope">
                  <span>{{ getServiceText(scope.row.relationType) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="isPhoto" label="产品拍照" width="120">
                <template #default="scope">
                  <span>{{ getPhotoText(scope.row.isPhoto) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="isOpenCheck" label="开箱质检" width="120">
                <template #default="scope">
                  <span>{{ getOpenCheckText(scope.row.isOpenCheck) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="上传时间" width="250">
                <template #default="scope">
                  <span>{{ formatDateTime(scope.row.createTime) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态" width="120">
                <template #default="scope">
                  <span>{{ getStatusText(scope.row.status) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="overTime" label="贴单时间" width="250">
                <template #default="scope">
                  <span>{{ formatDateTime(scope.row.overTime) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="国内单号" width="120">
                <template #default="scope">
                  <!-- 使用 v-html 指令来渲染包含换行符的字符串，但请注意 v-html 可能带来的 XSS 风险 -->
                  <span
                    v-html="scope.row.trackingNumber.replace(/\n/g, '<br>')"
                  ></span>
                </template>
              </el-table-column>
              <el-table-column
                prop="ozonOrderNumber"
                label="OZON单号"
                width="120"
              >
                <template #default="scope">
                  <!-- 使用 v-html 指令来渲染包含换行符的字符串，但请注意 v-html 可能带来的 XSS 风险 -->
                  <span
                    v-html="scope.row.ozonOrderNumber.replace(/\n/g, '<br>')"
                  ></span>
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注" width="150" />
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button
                    size="small"
                    type="danger"
                    :disabled="scope.row.status === 1"
                    @click="deleteButton(scope.row.trackingNumber)"
                  >
                    删除
                  </el-button>
                </template>
                <!-- 删除确认对话框 -->
              </el-table-column>
            </el-table>
            <el-pagination
              v-model:current-page="formData.page"
              v-model:page-size="formData.size"
              :page-sizes="[1, 10, 50, 100]"
              layout="sizes, prev, pager, next, jumper"
              :total="totalItems"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </el-card>
        </div>
      </div>
      <el-dialog v-model="dialogVisible" width="500" draggable>
        <span>确认删除记录吗?</span>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleDelete()"> 确认 </el-button>
          </div>
        </template>
      </el-dialog>
    </el-main>
  </el-container>
</template>  

<script>
import axios from "axios";

export default {
  data() {
    return {
      totalItems: 0,
      formData: {
        userCode: null,
        serviceTypeList: [],
        statusList: [],
        errorList: [],
        number: "",
        numberType: "1",
        page: 1,
        size: 10,
      },
      dialogVisible: false,
      tableData: [],
      tempDomesticNumber: "",
      deleteTrackNumber: null,
      tableCount: 0,
      deleteData: {
        trackingNumberIds: null,
      },
      warehouses: [
        { value: "warehouse1", label: "仓库1" },
        { value: "warehouse2", label: "仓库2" },
        // 更多仓库...
      ],
    };
  },
  methods: {
    goOrderPage() {
      this.$router.push({ name: "OrderPage" });
    },
    goListPage() {
      this.$router.push({ name: "OrderListPage" });
    },
    deleteButton(trackNumber) {
      console.log(this.dialogVisible);
      this.dialogVisible = true;

      this.deleteData.trackingNumberIds = trackNumber;
    },
    handleDelete() {
      (this.dialogVisible = false),
        // 使用axios发送POST请求
        axios
          .post(
            "https://uni.elsbhl.com/postOrder/logistics/order/delete",
            this.deleteData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.onSubmit();
            console.error("完成", response);
            // 请求成功处理
            this.$message.info("完成");
            // 这里可以添加其他成功处理逻辑
          })
          .catch((error) => {
            // 请求失败处理
            console.error("订单提交失败", error);
            // 这里可以添加其他失败处理逻辑
          });
    },
    formatDateTime(cellValue) {
      console.log("cellValue");
      console.log(cellValue);
      if (cellValue == null || cellValue == undefined || cellValue == "") {
        return "";
      }
      // 假设cellValue是createTime的值
      // 转换日期时间字符串为Date对象
      const date = new Date(cellValue);

      // 使用Date对象的方法获取年、月、日、时、分、秒
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份是从0开始的
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      // 拼接成所需的格式
      return `${year}年${month}月${day}日${hours}:${minutes}:${seconds}`;
    },
    getStatusText(status) {
      switch (status) {
        case 0:
          return "未操作";
        case 1:
          return "完成";
        case 2:
          return "异常";
        default:
          return "未知状态";
      }
    },

    getServiceText(relationType) {
      switch (relationType) {
        case 1:
          return "一个国内订单一个OZON订单";
        case 2:
          return "一个国内订单多个OZON订单";
        case 3:
          return "多个国内订单多个OZON订单";
        default:
          return "未知状态";
      }
    },

    getPhotoText(isPhoto) {
      if (isPhoto) {
        return "是";
      } else {
        return "否";
      }
    },

    getOpenCheckText(isOpenCheck) {
      if (isOpenCheck) {
        return "是";
      } else {
        return "否";
      }
    },

    handleStatusAll(event) {
      console.log(event);
      // 当全选框状态改变时触发
      if (event) {
        this.formData.statusList = ["-1", "0", "1", "2"];
      } else {
        this.formData.statusList = [];
      }
    },
    handleServiceAll(event) {
      console.log(event);
      // 当全选框状态改变时触发
      if (event) {
        this.formData.serviceTypeList = ["0", "1", "2", "3"];
      } else {
        this.formData.serviceTypeList = [];
      }
    },

    handleErrorAll(event) {
      console.log(event);
      // 当全选框状态改变时触发
      if (event) {
        this.formData.errorList = ["-1", "1", "2", "3", "4", "5", "6", "7"];
      } else {
        this.formData.errorList = [];
      }
    },

    checkCustomerCode() {
      const code = this.formData.userCode;
      if (code) {
        axios
          .get(
            `https://uni.elsbhl.com/postOrder/logistics/user/${encodeURIComponent(
              code
            )}`
          )
          .then((response) => {
            // 假设后端返回200表示验证成功，你可以根据后端实际返回的数据来调整这里的逻辑
            if (response.status === 200) {
              console.log(response);
              if (response.data.data === true) {
                this.$message.error("客户代码验证成功！");
              } else {
                this.$message.error("客户代码验证失败！");
              }
              // 验证成功，你可以在这里添加成功后的逻辑
            } else {
              // 验证失败
              this.$message.error("查询失败");
            }
          })
          .catch((error) => {
            // 请求出错
            this.$message.error("请求出错，请稍后再试！");
            console.error(error);
          });
      } else {
        this.$message.warning("请先填写客户代码！");
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.formData.size = val;
      this.onSubmit();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.onSubmit();
    },
    onSubmit() {
      const config = {
        method: "post",
        url: "https://uni.elsbhl.com/postOrder/logistics/order/get/list", // 注意使用 http:// 或 https:// 根据你的服务器配置
        headers: {
          "Content-Type": "application/json",
        },
        data: this.formData,
      };
      // 使用axios发送POST请求
      axios(config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            if (response.data.status === 200) {
              console.log("response.data.data");

              console.log(response.data.data);

              this.tableData = response.data.data.list;
              this.totalItems = response.data.data.pages;
              this.tableCount = response.data.data.total;
            } else {
              this.$message.error("客户代码验证失败！");
            }
            // 验证成功，你可以在这里添加成功后的逻辑
          } else {
            // 验证失败
            this.$message.error("查询失败");
          }
        })
        .catch((error) => {
          // 请求失败处理
          this.$message.error("查询失败");

          console.error(error);
          // 这里可以添加其他失败处理逻辑
        });
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>  

<style scoped>
.custom-radio-group {
  width: 100%;
  border: 1px solid #dcdfe6; /* 添加边框 */
  padding: 15px; /* 添加内边距 */
}

.custom-label {
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
  border-left: 5px solid #fc671a;
  font-size: 16px;
  margin: 10px 0px;
  padding: 0 10px;
  align-content: center;
  text-align: left;
}

.custom-label span {
  font-size: 14px;
  color: #999;
}

.label-border {
  margin-right: 15px; /* 调整单选按钮之间的间距 */
  width: 100%;
  border: 0px solid #dcdfe6; /* 添加边框 */
  padding: 15px; /* 添加内边距 */
  background-color: #333;
}

.header {
  height: 120px; /* 设置header的高度 */
  background-image: url("https://yunfulfillment.yunexpress.cn/assets/ytnew/images/cxbn_01.jpg");
  background-size: cover; /* 使背景图像覆盖整个元素 */
  background-position: center; /* 居中背景图 */
  color: #333;
  width: 100%; /* 确保header填满左右 */
  padding: 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* 添加内边距以避免内容紧贴边缘 */
  height: 100%; /* 确保内容高度与header一致 */
}

.logo img {
  height: 100%; /* 调整图标高度以填满header */
  cursor: pointer; /* 鼠标悬停时显示为手形光标 */
}

.nav-links {
  display: flex; /* 使用flex布局排列链接 */
  gap: 10px; /* 调整链接之间的间距，根据需要可增加或减少 */
}

.home-link {
  background-color: #ffffff; /* 背景为纯白 */
  height: 100%; /* 与header高度相同 */
  line-height: 120px; /* 与header高度一致，垂直居中 */
  padding: 0 20px; /* 添加左右内边距，适当控制左右间距 */
  text-decoration: none; /* 移除下划线 */
  color: #fc671a; /* 字体颜色 */
  display: flex; /* 使用flex更好地居中内容 */
  align-items: center; /* 垂直居中 */

  font-size: 15px;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 0px;
}

.home-link-no {
  height: 100%; /* 与header高度相同 */
  line-height: 120px; /* 与header高度一致，垂直居中 */
  padding: 0 20px; /* 添加左右内边距，适当控制左右间距 */
  text-decoration: none; /* 移除下划线 */
  color: #ffffff; /* 字体颜色 */
  display: flex; /* 使用flex更好地居中内容 */
  align-items: center; /* 垂直居中 */
  font-size: 15px;

  margin-right: 10px;
}

.footer {
  height: 160px; /* 设置footer的高度 */
  background-color: #212429;
  color: #333;
  text-align: center;
  line-height: 60px; /* 与footer高度一致，垂直居中 */
  width: 100%; /* 确保footer填满左右 */
}

.main {
  background-color: #ffffff;
  justify-content: center;
  color: #333;
  display: flex;
  padding-top: 0; /* 如果有的话，移除或调整这个值 */
  margin-top: 0; /* 如果有的话，移除或调整这个值 */
}

.i-info {
  margin: 2px;
  color: red;
}
.span-info {
  width: 40%;
}
.form-info {
  margin-top: 0; /* 移除或调整顶部外边距 */
  border: 1px solid #dcdfe6; /* 添加边框 */
  width: 100%;
  padding-left: 50px;
  border-radius: 0px; /* 可选：添加圆角边框 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); /* 可选：添加阴影 */
  height: 50%;
}

.custom-label .el-form-item__label {
  background-color: #f5f5f5; /* 替换成你想要的颜色 */
  padding: 5px; /* 可选：调整内边距以增加背景的可视效果 */
}

.prepend-text {
  height: 50%;
  padding-left: 50px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); /* 透明度较高的阴影 */
}

.custom-label .el-form-item__label {
  background-color: #f5f5f5; /* 替换成你想要的颜色 */
  padding: 5px; /* 可选：调整内边距以增加背景的可视效果 */
}

.prepend-text {
  height: 50px;
  font-weight: bold; /* 字体加粗 */
  align-content: center;
}
.user-code-input {
  width: 20%;
}
.number-input {
  width: 20%;
  margin: 30px;
}
.number-type-select {
  width: 115px;
  border: 0px;
}

.button-info {
  margin-top: 30px;
  height: 40px;
  width: 150px;
  border-radius: 0px;
  background-color: #fc671a;
  font-size: 20px;
  color: #fff;
}
.import-button {
  border-radius: 10px;
  background: #999999;
  width: 150px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  padding: 10px 18px;
  text-align: center;
  margin-right: 24px;
}
.mx-1 {
  margin-left: 10px;
}
.date-range-picker {
  display: flex;
  align-items: center;
}

.date-range-picker input {
  flex: 1;
  margin: 0 5px; /* 两边各留出5px的间隔 */
}

.separator {
  padding: 0 10px; /* 设置文字间的间隔 */
  font-size: 14px; /* 设置文字大小 */
}
.table-header-count {
  height: 50px;
  border: 0px solid #f5f5f5;
  background-color: #f5f5f5;
  padding-right: 50px;
  width: 100%;
  margin-top: 20px;
  align-content: center;
}
.el-card {
  margin-top: 20px;
  width: 100%;
  padding: 0 25px;
}
.label-font-size {
  font-size: 22px;
}
.warp {
  width: 1920px;
}

.el-table {
  --el-table-header-bg-color: #fc671a;
  --el-table-header-text-color: #fff;
}

/* 表头居中 */
.el-table__header {
  text-align: center; /* 表头文本居中 */
}

.demo-date-picker {
  margin-right: 20px;
}
</style>