<template>
  <el-container style="height: 95vh">
    <el-header class="header">
      <div class="header-content">
        <div class="logo" @click="goHome">
          <img src="https://your-icon-url.com/icon.png" alt="Logo" />
        </div>
        <div class="nav-links">
          
          <el-button @click="goHome" link class="home-link-no">首页</el-button>
          
          <el-button @click="goOrderPage"   class="home-link">提交订单</el-button>
        
          <el-button @click="goListPage" link  class="home-link-no">查询订单</el-button>
        </div>
      </div>
    </el-header>
    <el-main class="main">
      <div class="warp">
      <el-form label-position="top" class="form-info" :model="formData">
        <el-form-item label-position="top">
          <el-input
            v-model="formData.userCode"
            placeholder="正确填写客户代码"
            class="user-code-input"
            @blur="checkCustomerCode"
          >
            <template #prepend>
              <span class="prepend-text">客户代码</span>
            </template>
          </el-input>
          <el-text class="mx-1" type="danger">*</el-text>
          <el-text> 请填写客户代码，不填或错填将无法添加信息</el-text>
        </el-form-item>

        <el-form-item
          label-position="top"
          :label-style="{ backgroundColor: '#525151', padding: '5px' }"
        >
          <div class="custom-label">
            服务类型
            <span>( 此项为单选，若选择一项其余内容不可选 )</span>
          </div>
          <el-radio-group v-model="formData.type" class="custom-radio-group">
            <el-radio :value="1">一个国内订单一个OZON订单</el-radio>
            <el-radio :value="2">一个国内订单多个OZON订单</el-radio>
            <el-radio :value="3">多个国内订单一个OZON订单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <div class="input-group">
            <div  class="span-info">
            <i class="i-info">*</i>
            <span class="span-font-info">国内单号</span>
            </div>
            <el-input 
              type="textarea"
              v-model="formData.trackingNumbers"
              placeholder="国内单号,多个单号回车区分"
            />
          </div>
          <div class="input-group">
            <div  class="span-info">
            <i class="i-info">*</i>
            <span class="span-font-info">OZON单号</span>
            </div>
            <el-input
              type="textarea"
              v-model="formData.ozonOrderNumbers"
              placeholder="OZON单号,多个单号回车区分"
              clearable
            />
          </div>

          <div class="input-group">
            <span class="span-info">备注</span>
            <el-input
              type="textarea"
              v-model="formData.remark"
              placeholder="备注"
              clearable
            />
          </div>
        </el-form-item>
        <el-form-item label=" " label-position="top">
          <div class="custom-label">
            增值服务
            <span>( 请根据订单需要进行选择 )</span>
          </div>
          <el-checkbox-group v-model="formData.checkList">
            <el-checkbox label="产品拍照" value="1" />
            <el-checkbox label="开箱质检" value="2" />
          </el-checkbox-group>
        </el-form-item>
        <div>
          <!-- 一些内容 -->
          <hr />
        </div>
        <el-form-item>
          <el-upload  
            class="upload-demo"  
            action="#"  
             accept=".xlsx,.xls"
             :show-file-list="false"
            :on-change="handleChange"  
            :before-upload="beforeUpload"  
            :limit="1"
            :on-remove="handleRemove"  
            :on-success="handleSuccess"  
            :on-error="handleError" 
            ref="upload" 
          > 
          <el-button class="import-button"
            ><img src="assets/add2.png" alt />导入订单</el-button
          >
          </el-upload>
          <el-link href="/template/代打包批量上传.xlsx" class="link-container">
            <el-image
              src="/assets/yxz.png"
              class="img-container"
              style="margin-right: 10px"
              alt
            ></el-image>
            下载模版
          </el-link>
        </el-form-item>
        <div>
          <!-- 一些内容 -->
          <hr />
        </div>
        <el-form-item>
          <div class="button-container">
            <el-button class="button-info" @click="onSubmit"
              >确认提交</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      </div>
    </el-main>
  </el-container>
</template>  

<script>
import axios from "axios";
export default {
  name: "YourComponentName",
  data() {
    return {
      formData: {
        userCode: "",
        type: 1,
        trackingNumbers: "",
        remark: "",
        checkList: [],
        ozonOrderNumbers: "",
      },
      tempDomesticNumber: "",
    };
  },
  methods: {
    beforeUpload(file) {  
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||   
                      file.type === 'application/vnd.ms-excel';  
      if (!isExcel) {  
        this.$message.error('上传文件只能是 Excel 文件！');  
      }  
      const isLt5M = file.size / 1024 / 1024 < 5;  
      if (!isLt5M) {  
        this.$message.error('上传文件大小不能超过 5MB!');  
      }  
      return isExcel && isLt5M; // 返回true, 继续上传  
    },  
    handleChange(file) {  
      console.log(file);
        this.uploadFile(file.raw);  
         this.$refs.upload.clearFiles(); 
      
    }, 
    goOrderPage() {
      this.$router.push({ name: "OrderPage" });
    },
    goListPage() {
      this.$router.push({ name: "OrderListPage" });
    },
    async uploadFile(file) {  
      const formData = new FormData();  
      formData.append('file', file);  
      
      try {  
        const response = await axios.post('https://uni.elsbhl.com/postOrder/logistics/order/upload', formData, {  
          headers: {  
            'Content-Type': 'multipart/form-data'  
          }  
        });  
        if(response.status === 200) {
          this.$message.success('文件上传成功！');  
        } else {
          this.$message.error(response.data.msg);  
        }
      } catch (error) {  
        if (error.response) {  
          this.$message.error(error.response.data.msg);  
        } else {
          this.$message.error('文件上传失败！');  
          console.error(error);  
        }
      } 
    }  ,
    addDomesticNumber() {
      // 添加一个新的国内单号输入框
      if (this.tempDomesticNumber.trim() !== "") {
        this.formData.domesticNumber.push(this.tempDomesticNumber);
        this.tempDomesticNumber = ""; // 清空输入框
      } else {
        // 可选：如果输入为空，给出提示
        this.$message({
          message: "请输入有效的国内单号",
          type: "warning",
        });
      }
    },
    checkCustomerCode() {
      const code = this.formData.userCode;
      if (code) {
        axios
          .get(
            `https://uni.elsbhl.com/postOrder/logistics/user/${encodeURIComponent(code)}`
          )
          .then((response) => {
            // 假设后端返回200表示验证成功，你可以根据后端实际返回的数据来调整这里的逻辑
            if (response.status === 200) {
              console.log(response);
              if (response.data.data === true) {
                this.$message.success("客户代码验证成功！");
              } else {
                this.$message.error("客户代码验证失败！");
              }
              // 验证成功，你可以在这里添加成功后的逻辑
            } else {
              // 验证失败
              this.$message.error("客户代码验证失败！");
            }
          })
          .catch((error) => {
            // 请求出错
            this.$message.error("请求出错，请稍后再试！");
            console.error(error);
          });
      } else {
        this.$message.warning("请先填写客户代码！");
      }
    },
    handleServiceTypeChange() {
      console.log(this.formData.type);
      // 服务类型变化时可能需要的逻辑处理
    },
    onSubmit() {
      // 定义请求数据

      // 使用axios发送POST请求
      axios
        .post("https://uni.elsbhl.com/postOrder/logistics/order/save", this.formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.status === 200){
            this.$message.success(response.data.msg);
          }else{
            this.$message.error(response.data.msg);
          }
          // 请求成功处理
          console.log("订单提交成功", response);
          // 这里可以添加其他成功处理逻辑
        })
        .catch((error) => {
          // 请求失败处理
          this.$message.error(error.response.data.msg);
          console.error(error);
          // 这里可以添加其他失败处理逻辑
        
          
            console.error(error);
          // 这里可以添加其他失败处理逻辑
        });
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>  

<style scoped>
.custom-radio-group {
  width: 100%;
  border: 1px solid #dcdfe6; /* 添加边框 */
  padding: 15px; /* 添加内边距 */
}

.custom-label {
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
  border-left: 5px solid #fc671a;
  font-size: 16px;
  margin: 10px 0px;
  padding: 0 10px;
  align-content: center;
  text-align: left;
}

.custom-label span {
  font-size: 14px;
  color: #999;
}

.label-border {
  margin-right: 15px; /* 调整单选按钮之间的间距 */
  width: 100%;
  border: 0px solid #dcdfe6; /* 添加边框 */
  padding: 15px; /* 添加内边距 */
  background-color: #333;
}

.header {
  height: 120px; /* 设置header的高度 */
  background-image: url("https://yunfulfillment.yunexpress.cn/assets/ytnew/images/cxbn_01.jpg");
  background-size: cover; /* 使背景图像覆盖整个元素 */
  background-position: center; /* 居中背景图 */
  color: #333;
  width: 100%; /* 确保header填满左右 */
  padding: 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* 添加内边距以避免内容紧贴边缘 */
  height: 100%; /* 确保内容高度与header一致 */
}

.logo img {
  height: 100%; /* 调整图标高度以填满header */
  cursor: pointer; /* 鼠标悬停时显示为手形光标 */
}

.nav-links {
  display: flex; /* 使用flex布局排列链接 */
  gap: 10px; /* 调整链接之间的间距，根据需要可增加或减少 */
}

.home-link {
  background-color: #ffffff; /* 背景为纯白 */
  height: 100%; /* 与header高度相同 */
  line-height: 120px; /* 与header高度一致，垂直居中 */
  padding: 0 20px; /* 添加左右内边距，适当控制左右间距 */
  text-decoration: none; /* 移除下划线 */
  color: #fc671a; /* 字体颜色 */
  display: flex; /* 使用flex更好地居中内容 */
  align-items: center; /* 垂直居中 */
  
  font-size: 15px;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 0px;
}

.home-link-no {
  height: 100%; /* 与header高度相同 */
  line-height: 120px; /* 与header高度一致，垂直居中 */
  padding: 0 20px; /* 添加左右内边距，适当控制左右间距 */
  text-decoration: none; /* 移除下划线 */
  color: #ffffff; /* 字体颜色 */
  display: flex; /* 使用flex更好地居中内容 */
  align-items: center; /* 垂直居中 */
  font-size: 15px;
  
  margin-right: 10px;
}
.footer {
  height: 160px; /* 设置footer的高度 */
  background-color: #212429;
  color: #333;
  text-align: center;
  line-height: 60px; /* 与footer高度一致，垂直居中 */
  width: 100%; /* 确保footer填满左右 */
}

.main {
  background-color: #ffffff;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: top;
  padding-top: 0; /* 如果有的话，移除或调整这个值 */
  margin-top: 0; /* 如果有的话，移除或调整这个值 */
  
}
.img-container {
  display: flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  text-decoration: none; /* 移除链接的下划线 */
}

.link-container {
  margin: 20px;
}
/* 可以添加一些样式来调整图片大小，如果需要的话 */
.button-container {
  width: 100%;

  display: flex;
  justify-content: flex-end; /* 将按钮定位到容器的右侧 */
  align-items: center; /* 垂直居中对齐 */
}
.input-group {
  display: inline-flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中对齐 */
  padding: 10px; /* 添加一些内边距 */
  width: 30%;
}
.i-info {
  margin: 5px;
  color: red;
}
.span-info {
  text-align: right;
  margin-right: 10px;
  width: 40%;
}
.form-info {
  margin-top: 0; /* 移除或调整顶部外边距 */
  border: 1px solid #dcdfe6; /* 添加边框 */
  width: 100%;
  padding: 50px 50px;
  height: 70%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); /* 透明度较高的阴影 */
}
.custom-label .el-form-item__label {
  background-color: #f5f5f5; /* 替换成你想要的颜色 */
  padding: 5px; /* 可选：调整内边距以增加背景的可视效果 */
}

.prepend-text {
  background-color: #fc671a; /* 你想要的背景色 */
  padding: 10% 35%; /* 内边距 */
  border-radius: 0px; /* 圆角，可选 */
  color: #fff; /* 文字颜色 */
  font-size: 16px;
}
.user-code-input {
  width: 40%;
}

.button-info {
  height: 50px;
  width: 230px;
  border-radius: 10px;
  background-color: #fc671a;
  font-size: 20px;
  color: #fff;
}
.import-button {
  border-radius: 10px;
  background: #999999;
  width: 150px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  padding: 10px 18px;
  text-align: center;
  margin-right: 24px;
}
.mx-1 {
  margin-left: 10px;
}
.input-info{
  width: 200px;

}
.warp{
  width:1920px;
  height: 830px;
}
</style>