import { createRouter, createWebHistory } from 'vue-router';  
import Home from '@/views/Home.vue'; // 请根据你的文件路径调整  
import OrderPage from '@/views/OrderPage.vue'; // 请根据你的文件路径调整
import OrderListPage from '@/views/OrderListPage.vue'; // 请根据你的文件路径调整
import AdminOrderListPage from '@/views/AdminOrderListPage.vue'; // 请根据你的文件路径调整
import UserCreate from '@/views/UserCreate.vue'; // 请根据你的文件路径调整

const routes = [  
  {  
    path: '/',  
    name: 'Home',  
    component: Home,  
  },  
  {  
    path: '/order_create',  
    name: 'OrderPage', // 修改名称为 OrderPage  
    component: OrderPage,  
  },   
  {  
    path: '/order_search',  
    name: 'OrderListPage', // 修改名称为 OrderPage  
    component: OrderListPage,  
  },  
  {  
    path: '/admin_search',  
    name: 'AdminOrderListPage', // 修改名称为 OrderPage  
    component: AdminOrderListPage,  
  },  
  {  
    path: '/user_create',  
    name: 'UserCreate', // 修改名称为 OrderPage  
    component: UserCreate,  
  },  
];  

const router = createRouter({  
  history: createWebHistory(),  
  routes,  
});  

export default router;