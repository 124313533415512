<template>  
  <div class="yun-fulfilment-page">  
    <!-- 背景图片 -->  
    <div class="background-image"></div>  
  
    <!-- 页面内容 -->  
    <div class="content">  
      <span style="font-size: 45px;">以持续技术创新为客户不断创造价值</span>  
      <div class="options">  
        <button @click="handleSubmitOrder">提交订单</button>  
        <button @click="handleQueryOrder">查询订单</button>  
      </div>  
    </div>  
  </div>  
</template>  
  
<script>  
export default {  
  name: 'YunFulfilmentPage',  
  methods: {  
    handleSubmitOrder() {  
      console.log('提交订单');  
      // 跳转到 FulfillmentPage 页面  
       this.$router.push({ name: 'OrderPage' }); 
      // 或者使用路径跳转  
      // this.$router.push('/fulfillment');  
    },  
    handleQueryOrder() {  
      console.log('查询订单');  
       this.$router.push({ name: 'OrderListPage' }); 
      // 在这里添加查询订单的逻辑  
    },  
    handleServiceQuote() {  
      console.log('服务报价');  
      // 在这里添加服务报价的逻辑  
    }  
  }  
}  
</script>  
  
<style scoped>  
.yun-fulfilment-page {  
  position: relative;  
  height: 100vh; /* 使页面高度占满视窗 */  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  overflow: hidden;  
}  
  
.background-image {  
  position: absolute;  
  top: 0;  
  left: 0;  
  width: 100%;  
  height: 100%;  
  background-image: url('https://yunfulfillment.yunexpress.cn/assets/ytassets/images/bg.jpg');  
  background-size: cover;  
  background-position: center;  
  z-index: -1; /* 确保内容显示在背景之上 */  
}  
  
.content {  
  text-align: center;  
  color: white; /* 假设需要白色文字来与背景对比 */  
  z-index: 1; /* 确保内容在背景之上 */  
}  
  
.options button {  
  margin: 90px 50px;  
  padding: 10px 40px;  
  font-size: 22px;  
  cursor: pointer;  
  border: none;  
  border-radius: 5px;  
  background-color: #f8f8f8; /* 蓝色背景，可以根据需要调整 */  
  color: rgb(248, 79, 1);  
}  
  
.options button:hover {  
  background-color: #f8f8f8; /* 鼠标悬停时的颜色 */  
}  
</style>